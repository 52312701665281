import { init as initScroll } from './scroll'
import { calculateNavHeight, handleNavigationContainer, initToggler as initNavbar } from './navbar'
import { setTopMargin as setHeaderSpacing } from './header'
import { initContactSubmit } from './contact'

window.addEventListener('calculatedNavHeight', ((event: CustomEvent) => {
  if (event.detail && event.detail.navHeight) setHeaderSpacing(event.detail.navHeight)
}) as EventListener)

calculateNavHeight()
initNavbar()
handleNavigationContainer()
initScroll()
initContactSubmit()
