const formContainerId = '#contact'
const submitElementId = '#contact-submit'

export const initContactSubmit = () => {
    const element = $(submitElementId)

    element.on('click', function (e) {
      const formContainer = $(formContainerId)

      const requiredElements = formContainer.find('[data-required="true"]')
      requiredElements.attr('required', 'required')

      const conditionContainer = $($(this).data('rel'))
      const conditionCheckbox = conditionContainer?.find('[type="checkbox"]')
      const conditionCheckboxVal = conditionCheckbox?.prop('checked')
      let condition = true

      requiredElements.each(function () {
        condition = condition && ($(this).is(':valid'))
      })

      conditionCheckbox.on('change', function () {
        if ($(this).prop('checked')) conditionContainer.removeClass('error')
        else conditionContainer.addClass('error')
      })

      if (!conditionCheckboxVal) conditionContainer.addClass('error')
      if (!condition || !conditionCheckboxVal) e.preventDefault()
    })
}
