const navbarClass = '.js-navbar'
const togglerClass = '.js-navbar-toggler'
const navItemClass = '.js-nav-item'

export const initToggler = () => {
  const toggler = $(togglerClass)

  if (toggler) {
    toggler.on('click', function (e?: JQuery.TriggeredEvent) {
      const element = $(this)
      const data = element.data()
      const state = data.state
      const target = $(data.target)

      if (state === 'collapsed') {
        element.data('state', 'expanded')
        element.attr('data-state', 'expanded')
        element.attr('aria-expanded', 'true')
        target.toggleClass('is-visible')
      }

      if (state === 'expanded') {
        element.data('state', 'collapsed')
        element.attr('data-state', 'collapsed')
        element.attr('aria-expanded', 'false')
        target.toggleClass('is-visible')
      }
    })

    window.addEventListener('touchstart', ((e?: JQuery.TriggeredEvent) => {
      const navBar = $(navbarClass)
      const element = toggler
      const data = element.data()
      const state = data.state
      const target = $(data.target)
      if (e && !navBar.is(e.target) && navBar.has(e.target).length === 0 && state === 'expanded') {
        element.data('state', 'collapsed')
        element.attr('data-state', 'collapsed')
        element.attr('aria-expanded', 'false')
        target.toggleClass('is-visible')
      }
    }) as unknown as EventListener)
  }

}

export const handleNavigationContainer = (e?: JQuery.TriggeredEvent) => {
  const toggler = $(togglerClass)
  const navItems = $(navItemClass)

  if (toggler && navItems) navItems.on('click', function (e?: JQuery.TriggeredEvent) {
    const element = toggler
    const data = element.data()
    const state = data.state
    const target = $(data.target)

    if (state === 'expanded') {
      element.data('state', 'collapsed')
      element.attr('data-state', 'collapsed')
      element.attr('aria-expanded', 'false')
      target.toggleClass('is-visible')
    }
  })
}

export const calculateNavHeight = () => {
  const target = $(navbarClass)

  if (target) window.dispatchEvent(new CustomEvent('calculatedNavHeight', { detail: { navHeight: target.outerHeight() } }))
}

