const targetClass = '.js-main'
const scrollTargetClass = '.js-scroll-section'

export const setTopMargin = (value) => {
  const target = $(targetClass)
  const scrollTargets = $(scrollTargetClass)
  const spacing = `${value / 16}rem`
  if (target) target.css('margin-top', spacing)
  if (scrollTargets) scrollTargets.css({
    'margin-top': `-${spacing}`,
    'padding-top': spacing
  })
}
