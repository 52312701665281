import smoothscroll from 'smoothscroll-polyfill'
import { debounce } from 'lodash'

const targetClass = 'js-scroll-target'

const animateCSS = (element, animationName, callback) => {
  const node = element
  node.classList.add('animated', animationName)

  function handleAnimationEnd () {
    // node.classList.remove('animated', animationName)
    node.removeEventListener('animationend', handleAnimationEnd)

    if (typeof callback === 'function') callback()
  }

  node.addEventListener('animationend', handleAnimationEnd)
}

const scrollTargetInViewport = debounce(() => {
  const targets = [...document.getElementsByClassName(targetClass)]
  const windowHeight = window.innerHeight

  if (targets && targets.length) {
    targets.forEach(target => {
      const dimensions = target.getBoundingClientRect()
      const distanceTop = dimensions.top
      const threshold = windowHeight
      const isInViewport = Math.abs(distanceTop) < threshold

      if (isInViewport) window.dispatchEvent(new CustomEvent('scrollTargetInViewport', { detail: { target: target } }))
    })
  }
}, 200)

const handleScrollTargetInViewport = (event: CustomEvent) => {
  const element = event.detail.target
  if (element && !element.classList.contains('animated')) animateCSS(element, 'bounceIn', () => {
    element.classList.add('is-visible')
    // window.removeEventListener('scrollTargetInViewport', handleScrollTargetInViewport as EventListener)
    // window.removeEventListener('scroll', scrollTargetInViewport)
    // window.removeEventListener('load', scrollTargetInViewport)
  })
}

export const init = () => {
  smoothscroll.polyfill()
  window.addEventListener('load', scrollTargetInViewport)
  window.addEventListener('scroll', scrollTargetInViewport)
  window.addEventListener('scrollTargetInViewport', handleScrollTargetInViewport as EventListener)
}

